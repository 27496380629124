<template>
  <div class="row">
    <div class="col-md-12"><edit-profile-form :userObj="getUserObj" v-if="getUserObj" @update-user="updateUser"> </edit-profile-form></div>
    <div class="col-md-12"><!-- <user-card> </user-card> --><AlertUsersTableGBS :users="getUserObj.alertsUsers" v-if="getUserObj" @update="updateAlertUsers" /></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { patchUser } from "./../../services/api";
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
import AlertUsersTableGBS from "../Tables/AlertUsersTableGBS.vue";
/* import UserCard from "./UserProfile/UserCard.vue"; */

export default {
  components: {
    EditProfileForm,
    AlertUsersTableGBS
    /* UserCard */
  },
  computed: {
    ...mapGetters({
      getUserObj: "user/getUserObj"
    })
  },
  methods: {
    ...mapActions({
      fetchUserInfo: "user/fetchUserInfo"
    }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async updateUser(newUserObj) {
      this.startLoading();
      await patchUser({ password: newUserObj.password });
      this.stopLoading();
    },
    async updateAlertUsers(newAlertUsers) {
      this.startLoading();
      await patchUser({ alertsUsers: newAlertUsers });
      this.stopLoading();
    }
  },
  mounted() {
    if (!this.getUserObj) {
      this.fetchUserInfo();
    }
  }
};
</script>
<style></style>
