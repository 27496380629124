<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <template slot="header">
              <img src="v2/img/svg-logo.svg" alt="" />
              <!-- <h1 class="card-title">Log in</h1> -->
            </template>

            <div>
              <ValidationProvider name="username" rules="required" v-slot="{ passed, failed }">
                <base-input
                  @input="inputChanged"
                  required
                  v-model="username"
                  type="text"
                  placeholder="Username"
                  addon-left-icon="tim-icons icon-single-02"
                  :class="[{ 'has-success': passed && !loginErrorMessage }, { 'has-danger': failed || loginErrorMessage }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider name="password" rules="required|min:5" v-slot="{ passed, failed }">
                <base-input
                  @input="inputChanged"
                  required
                  v-model="password"
                  placeholder="Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="loginErrorMessage"
                  :class="[{ 'has-success': passed && !loginErrorMessage }, { 'has-danger': failed || loginErrorMessage }]"
                >
                </base-input>
              </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="info" class="mb-3" size="lg" block>
                {{ $t("login.getStarted") }}
              </base-button>
              <!-- <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    Create Account
                  </router-link>
                </h6>
              </div> -->

              <!-- <div class="pull-right">
                <h6>
                  <a href="mailto:info@gbscloud.eu" class="link footer-link">{{ $t("login.needHelp") }}</a>
                </h6>
              </div> -->
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import router from "../../routes/router";

export default {
  data() {
    return {
      username: "",
      password: "",
      subscribe: true,
      loginErrorMessage: null,
    };
  },
  methods: {
    ...mapActions({
      login: "user/login",
    }),
    inputChanged() {
      this.loginErrorMessage = null;
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async submit() {
      //alert("Form has been submitted!");
      try {
        this.loginErrorMessage = null;
        this.startLoading();
        await this.login({ username: this.username, password: this.password });
        if (this.username === "admin") {
          router.push("admin");
        } else {
          router.push("dashboard");
        } // Stop loading implicita in dashboard
      } catch (e) {
        console.log(e);
        this.stopLoading();
        // Credenziali errate
        if (e.response && e.response.status === 403) {
          this.loginErrorMessage = "Credenziali errate";
        }
      }
    },
  },
};
</script>
<style scoped>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
