var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-4 col-md-6 ml-auto mr-auto"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card-login card-white"},[_c('template',{slot:"header"},[_c('img',{attrs:{"src":"v2/img/svg-logo.svg","alt":""}})]),_c('div',[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('base-input',{class:[{ 'has-success': passed && !_vm.loginErrorMessage }, { 'has-danger': failed || _vm.loginErrorMessage }],attrs:{"required":"","type":"text","placeholder":"Username","addon-left-icon":"tim-icons icon-single-02"},on:{"input":_vm.inputChanged},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('base-input',{class:[{ 'has-success': passed && !_vm.loginErrorMessage }, { 'has-danger': failed || _vm.loginErrorMessage }],attrs:{"required":"","placeholder":"Password","addon-left-icon":"tim-icons icon-lock-circle","type":"password","error":_vm.loginErrorMessage},on:{"input":_vm.inputChanged},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{staticClass:"mb-3",attrs:{"native-type":"submit","type":"info","size":"lg","block":""}},[_vm._v(" "+_vm._s(_vm.$t("login.getStarted"))+" ")])],1)],2)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }