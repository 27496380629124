<template>
  <!-- <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Log</h2>
      <p class="text-center">
        In questa pagina puoi consultare i log.
      </p>
    </div> -->
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <h5 slot="header" class="title">{{ $t("userDetails.notificationsUsers") }}:</h5>
        <ValidationObserver ref="form2">
          <form @input="validateForm">
            <div>
              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"> </el-option>
                </el-select>

                <base-input>
                  <!-- <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input> -->
                  <base-button
                    size="sm"
                    type="info"
                    class="button add-button"
                    menu-on-right=""
                    tag="div"
                    title-classes="btn btn-link btn-icon"
                    :class="{ 'float-left': $rtl.isRTL }"
                    @click="addAlertUser"
                  >
                    <i class="tim-icons icon-simple-add"></i>
                    {{ $t("table.add") }}
                  </base-button>
                </base-input>
              </div>
              <el-table :data="queriedData">
                <!-- Value Type -->
                <el-table-column sortable :label="$t('userDetails.name')" min-width="160" property="name" align="left">
                  <template slot-scope="props">
                    <ValidationProvider class="vprovider" name="Name" :vid="'name' + props.$index" rules="required|min:4" v-slot="{ passed, failed }">
                      <base-input
                        v-model="props.row.name"
                        :disabled="!props.row.editing"
                        type="text"
                        label=""
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }, 'minimal']"
                      >
                      </base-input>
                    </ValidationProvider>
                  </template>
                </el-table-column>
                <el-table-column label="Email" min-width="160" property="email" align="left">
                  <template slot-scope="props">
                    <ValidationProvider class="vprovider" name="Email" :vid="'email' + props.$index" rules="required|email" v-slot="{ passed, failed }">
                      <base-input
                        v-model.trim="props.row.email"
                        :disabled="!props.row.editing"
                        type="email"
                        label=""
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }, 'minimal']"
                      >
                      </base-input>
                    </ValidationProvider>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('userDetails.phone')" min-width="180" property="phone" align="left">
                  <template slot-scope="props">
                    <ValidationProvider
                      class="vprovider"
                      name="Phone"
                      :vid="'phone' + props.$index"
                      :rules="{ required: false, regex: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/ }"
                      v-slot="{ passed, failed }"
                    >
                      <base-input v-model.trim="props.row.phone" :disabled="!props.row.editing" label="" :class="[{ 'has-success': passed }, { 'has-danger': failed }, 'minimal']">
                      </base-input>
                    </ValidationProvider>
                  </template>
                </el-table-column>
                <el-table-column sortable :label="$t('userDetails.devOfflineAlerts')" min-width="180" property="enabled" align="left">
                  <template slot-scope="props">
                    <base-switch v-model="props.row.devOfflineAlerts" :disabled="!props.row.editing" type="info" on-text="ON" off-text="OFF"></base-switch>
                  </template>
                </el-table-column>
                <el-table-column sortable :label="$t('userDetails.enabled')" min-width="120" property="enabled" align="left">
                  <template slot-scope="props">
                    <base-switch v-model="props.row.enabled" :disabled="!props.row.editing" type="info" on-text="ON" off-text="OFF"></base-switch>
                  </template>
                </el-table-column>
                <el-table-column :min-width="135" align="right" :label="$t('userDetails.actions')">
                  <div slot-scope="props">
                    <base-button @click.native="handleEdit(props.$index, props.row)" class="edit btn-link" type="warning" size="sm" icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <base-button @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon>
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>

            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">{{ $t("table.showing") }} {{ from + 1 }} {{ $t("table.to") }} {{ to }} {{ $t("table.of") }} {{ total }} {{ $t("table.entries") }}</p>
              </div>

              <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </base-pagination>
              <base-button type="primary" class="btn-fill" :disabled="!validForm" @click="updateAlertUsers">
                {{ $t("table.save") }}
              </base-button>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BaseSwitch } from "src/components/index";
import { BasePagination } from "src/components";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
  },
  props: {
    users: Array,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.alertUsers;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    tableData() {
      return this.alertUsers;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    },
  },
  data() {
    return {
      password: "",
      confirmation: "",
      prova: "",
      validForm: true,
      alertUsers: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
    };
  },
  methods: {
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    async validateForm() {
      this.validForm = await this.$refs.form2.validate();
      console.log(this.validForm);
    },
    updateAlertUsers() {
      this.alertUsersToUpdate = JSON.parse(JSON.stringify(this.alertUsers));
      for (let i = 0; i < this.alertUsersToUpdate.length; i++) {
        delete this.alertUsersToUpdate[i].editing;
        if (this.alertUsersToUpdate[i].phone == "") delete this.alertUsersToUpdate[i].phone;
      }

      this.$emit("update", this.alertUsersToUpdate);
    },
    addAlertUser() {
      this.alertUsers.push({
        name: "Alert User",
        email: "",
        phone: "",
        enabled: true,
        editing: true,
        devOfflineAlerts: false
      });
      //setTimeout(() => this.validateForm(), 20);
    },
    stopLoading() {
      this.$loading().close();
    },
    handleEdit(index, row) {
      const prevStatus = this.alertUsers[index].editing;
      this.alertUsers = this.alertUsers.map((au) => {
        return { ...au, editing: false };
      });
      if (prevStatus === false) {
        this.alertUsers[index].editing = true;
      }
    },
    handleDelete(index, row) {
      this.alertUsers.splice(index, 1);
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
      //setTimeout(() => this.validateForm(), 20);
    },
  },
  async mounted() {
    // Fuse search initialization.
    /* this.fuseSearch = new Fuse(this.tableData, {
      keys: ["payload.device"],
      threshold: 0.3
    }); */

    // Sono da Admin
    /* if (this.userID) {
      await store.dispatch("user/setUserID", props.userID); // Per il momento store inutilizzato
      const logs = await getLogs(undefined, "threshold-alert", props.userID);
      state.currentLogs = logs;
    } else { */
    this.startLoading();
    //const logs = await getLogs(undefined, "threshold-alert", undefined);
    this.alertUsers = JSON.parse(JSON.stringify(this.users));
    this.alertUsers = this.alertUsers.map((au) => {
      return { ...au, editing: false };
    });
    this.stopLoading();
    /* } */
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    // Valido ad ogni cambiamento dell'array
    alertUsers() {
      setTimeout(this.validateForm, 20);
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
a {
  color: white;
}
</style>
