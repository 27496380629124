<template>
  <card>
    <h5 slot="header" class="title">{{ $t("userDetails.editProfile") }}:</h5>
    <ValidationObserver ref="form">
      <form @submit.prevent="updateProfile" @input="validateForm">
        <div class="row">
          <div class="col-md-5">
            <base-input type="text" :label="$t('userDetails.company')" :disabled="true" :placeholder="$t('userDetails.company')" v-model="user.company"> </base-input>
          </div>
          <div class="col-md-3">
            <base-input type="text" :label="$t('userDetails.username')" :disabled="true" :placeholder="$t('userDetails.username')" v-model="user.username"> </base-input>
          </div>
          <div class="col-md-4">
            <base-input type="email" :label="$t('userDetails.emailAddress')" :disabled="true" :placeholder="$t('userDetails.emailAddress')" v-model="user.email"> </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <base-input type="text" :label="$t('userDetails.firstName')" :disabled="true" :placeholder="$t('userDetails.firstName')" v-model="user.firstName"> </base-input>
          </div>
          <div class="col-md-6">
            <base-input type="text" :label="$t('userDetails.lastName')" :disabled="true" :placeholder="$t('userDetails.lastName')" v-model="user.lastName"> </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <base-input type="text" :label="$t('userDetails.VAT')" :disabled="true" :placeholder="$t('userDetails.VAT')" v-model="user.VAT"> </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              class="vprovider"
              name="Password"
              vid="password"
              :rules="{ required: true, regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/, confirmed: 'confirmation' }"
              :custom-messages="{ regex: $t('validation.pswRequirements'), confirmed: $t('validation.pswEquals') }"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                required
                v-model="password"
                type="password"
                :label="$t('userDetails.password')"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
              </base-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              class="vprovider"
              name="Confirm Password"
              vid="confirmation"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                required
                v-model="passwordConfirmation"
                type="password"
                :label="$t('userDetails.confirmPassword')"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
              </base-input>
            </ValidationProvider>
          </div>
        </div>

        <!-- <div class="row">
        <div class="col-md-12">
          <base-input type="text" label="Address" :disabled="true" placeholder="Home Address" v-model="user.address"> </base-input>
        </div>
      </div> -->

        <!-- <div class="row">
        <div class="col-md-4">
          <base-input type="text" label="City" :disabled="true" placeholder="City" v-model="user.city"> </base-input>
        </div>
        <div class="col-md-4">
          <base-input type="text" label="Country" :disabled="true" placeholder="Country" v-model="user.country"> </base-input>
        </div>
        <div class="col-md-4">
          <base-input label="Postal Code" :disabled="true" placeholder="ZIP Code" v-model="user.postalCode"> </base-input>
        </div>
      </div> -->

        <!-- <div class="row">
        <div class="col-md-12">
          <base-input label="About Me">
            <textarea class="form-control" :disabled="true" placeholder="ZIP Code" v-model="user.aboutMe"> </textarea>
          </base-input>
        </div>
      </div> -->

        <base-button native-type="submit" type="primary" class="btn-fill" :disabled="!validForm">
          {{ $t("table.save") }}
        </base-button>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
export default {
  props: {
    userObj: Object,
  },
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      validForm: false,
      user: {
        company: "Creative Code Inc.",
        username: "michael23",
        email: "",
        firstName: "Mike",
        lastName: "Andrew",
        address: "Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09",
        city: "New York",
        country: "USA",
        postalCode: "",
        aboutMe: `Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo.`,
      },
    };
  },
  methods: {
    // TODO: implementare anche altri campi
    updateProfile() {
      this.$emit("update-user", {
        password: this.password,
      });
    },
    async validateForm() {
      this.validForm = await this.$refs.form.validate();
    },
  },
  mounted() {
    this.user = JSON.parse(JSON.stringify(this.userObj));
  },
};
</script>
<style></style>
